import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import Layout from "components/layouts/base-layout/base-layout"
import themeConfig from "utils/theme-config"
import Contacts from "components/homepage/contacts"
import AboutUs from "components/homepage/about-us"
import PostsFeed from "components/homepage/posts-feed"
import CategoriesList from "components/homepage/categories-list"

const { screens } = themeConfig

const Homepage = props => {
  if (!props.data || !props.data.wpPage) {
    return null
  }
  const {
    className,
    location,
    data: {
      wpPage: {
        excerpt,
        featuredImage,
        language,
        seo,
        title,
        translations,
        template,
        uri,
      },
      latestPostsFeed,
      rawMenus,
      firstLevelCategories,
      defaultCategory,
      wpSettings,
      commonThemeSettings,
      searchUrls,
    },
  } = props

  if (!commonThemeSettings) {
    // no default theme settings page for the current language
    return (
      <div>
        Please create a settings page for the current language: {language.code}
      </div>
    )
  }
  if (!template?.acfHomepage) {
    return (
      <div>
        Please select the correct template for the Homepage of the current
        language: {language.code}
      </div>
    )
  }
  const homepageSections = template.acfHomepage
  const {
    acfThemeSettings: { generalSettings, footer, search, socialNetworks },
  } = commonThemeSettings

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={excerpt}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={title}
          translations={translations}
        />
        <main className="main-content">
          {homepageSections?.aboutUs && (
            <AboutUs config={homepageSections.aboutUs} />
          )}
          {homepageSections?.categoriesList && (
            <CategoriesList
              categories={firstLevelCategories}
              config={homepageSections.categoriesList}
              defaultCategory={defaultCategory}
            />
          )}
          {homepageSections?.postsFeed && (
            <PostsFeed
              className="posts-feed"
              config={homepageSections.postsFeed}
              language={language}
              postsFeed={latestPostsFeed}
            />
          )}
          {homepageSections?.contacts && (
            <Contacts contacts={homepageSections.contacts} />
          )}
        </main>
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    defaultCategory: PropTypes.object,
    firstLevelCategories: PropTypes.object,
    latestPostsFeed: PropTypes.object,
    rawMenus: PropTypes.object,
    wpPage: PropTypes.shape({
      content: PropTypes.string,
      dateGmt: PropTypes.string,
      excerpt: PropTypes.string,
      featuredImage: PropTypes.object,
      language: PropTypes.object,
      acfHomepage: PropTypes.shape({
        aboutUs: PropTypes.object,
        categoriesList: PropTypes.object,
        postsFeed: PropTypes.object,
        contacts: PropTypes.object,
      }),
      seo: PropTypes.object,
      slug: PropTypes.string,
      template: PropTypes.object,
      title: PropTypes.string,
      translations: PropTypes.array,
      uri: PropTypes.string,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
  }),
  location: PropTypes.object,
}

const StyledHomepage = styled(Homepage)`
  .main-content {
    position: relative;
    z-index: 20;
  }
  .about-us {
    position: relative;
    color: var(--light);
    background-color: var(--dark);
  }

  .about-us-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .about-us-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .about-us-content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
  }

  .about-us-title {
    position: relative;
    padding: 2rem 1rem;
    font-size: 6vw;
    line-height: 2.5rem;
    &:after {
      background: var(--tertiary);
    }
  }

  .contacts-title {
    &:after {
      background: var(--secondary);
    }
  }

  .about-us-description {
    padding: 1rem;
    margin-top: 3rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    p {
      margin-bottom: 2rem;
    }
  }

  .about-us-cta {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .categories-flat-list-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .posts-feed {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .posts-feed-title {
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 500;
    margin: 2rem 0;
  }

  .posts-feed-description {
    margin: 2rem 0;
  }

  .contacts {
    background-color: var(--gray1);
  }

  .contacts-content {
    padding: 3rem 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center;
  }

  .contacts-title {
    position: relative;
    padding: 2rem 1rem 4rem 1rem;
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem;
  }

  .contacts-description {
    position: relative;
    padding: 4rem 1rem 2rem 1rem;
    text-align: center;
  }

  .contacts-cta {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .gi-overlay {
    &:after {
      background: rgba(0, 0, 0, 0.45);
    }
  }
  @media only screen and (min-width: ${screens.lg}) {
    .categories-flat-list-container {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
    .posts-feed {
      text-align: center;
      padding: 0px;
    }
    .about-us-title {
      font-size: 2.25rem;
    }
  }
`

export const pageQuery = graphql`
  query Homepage(
    $id: String
    $languageCode: WpLanguageCodeEnum
    $menusLocations: [WpMenuLocationEnum]
    $defaultCategoryId: String
    $themeSettingsPageId: String
  ) {
    wpPage(id: { eq: $id }) {
      ...PostInfo
      template {
        ... on WpTemplate_Homepage {
          acfHomepage {
            aboutUs {
              description
              cta
              image {
                ...FullWidthGatsbyImage
              }
              link {
                ... on WpCommonPost {
                  id
                  slug
                  uri
                }
              }
              title
            }
            categoriesList {
              enableCategoriesFeed
            }
            postsFeed {
              title
              description
              enableLoadMore
              fieldGroupName
              loadMoreButtonText
              loadMorePostsPerPage
              numberOfLatestPosts
              showLatestPosts
            }
            contacts {
              description
              cta
              link {
                ... on WpCommonPost {
                  id
                  slug
                  uri
                }
              }
              title
            }
          }
        }
      }
    }
    firstLevelCategories: allWpCategory(
      filter: {
        language: { code: { eq: $languageCode } }
        parentDatabaseId: { eq: null }
      }
      sort: { order: ASC, fields: acfTaxonomy___termOrder }
    ) {
      nodes {
        id
        uri
        name
        slug
      }
    }
    latestPostsFeed: allWpPost(
      limit: 12
      filter: { language: { code: { eq: $languageCode } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...DryPostCardInfo
        id
        categories {
          nodes {
            name
            id
            slug
            uri
          }
        }
      }
    }
    defaultCategory: wpCategory(id: { eq: $defaultCategoryId }) {
      id
      slug
      name
    }
    ...LocalizedMenus
    ...WpSettings
    ...CommonThemeSettings
    ...SearchUrls
  }
`

export { Homepage }
export default StyledHomepage
