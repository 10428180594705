import React from "react"
import CategoryFlatList from "components/categories-flat-list/categories-flat-list"
import PropTypes from "prop-types"

const CategoriesList = ({ categories, config, defaultCategory }) => {
  return config?.enableCategoriesFeed && categories?.nodes ? (
    <section className="categories-flat-list-container">
      <CategoryFlatList
        list={categories.nodes}
        exclude={[defaultCategory]}
      ></CategoryFlatList>
    </section>
  ) : null
}

CategoriesList.propTypes = {
  config: PropTypes.object,
  categories: PropTypes.object,
  defaultCategory: PropTypes.object,
}
export default CategoriesList
