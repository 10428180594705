import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import themeConfig from "utils/theme-config"
const { screens } = themeConfig

const CategoriesFlatList = props => {
  const { className, list, exclude } = props
  let excludeSlugArray = exclude.map(element => {
    return element.slug !== null && element.slug !== undefined
      ? element.slug
      : null
  })
  excludeSlugArray = excludeSlugArray.filter(element => element !== null)
  return (
    <nav className={className}>
      <ul className="categories-flat-list dry-container">
        {list.map(element => {
          return (
            element.slug &&
            element.name &&
            !excludeSlugArray.includes(element.slug) && (
              <li className="element" key={element.slug}>
                <Link to={element.uri}>{element.name}</Link>
              </li>
            )
          )
        })}
      </ul>
    </nav>
  )
}

CategoriesFlatList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  exclude: PropTypes.array,
}

CategoriesFlatList.defaultProps = { exclude: [] }

const StyledCategoriesFlatList = styled(CategoriesFlatList)`
  .categories-flat-list .element {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:before,
      &:after {
        width: 2rem;
        height: 2px;
        background-color: var(--tertiary);
        content: "";
      }
      &:before {
        margin-right: 2rem;
      }
      &:after {
        margin-left: 2rem;
      }
    }
  }
  @media only screen and (min-width: ${screens.lg}) {
    .categories-flat-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .element {
        width: auto;
        flex-shrink: 0;
        flex-grow: 0;
        a {
          padding: 0 1rem;
          &:before {
            display: none;
          }
          &:after {
            position: absolute;
            width: 2px;
            height: 1rem;
            margin: 0;
            right: 0;
          }
        }
        &:last-child {
          a:after {
            display: none;
          }
        }
      }
    }
  }
`

export default StyledCategoriesFlatList
export { CategoriesFlatList }
